<template>
  <div>
    <footer class="footer">
      <div class="footer_logo_box">
        <div class="footer_logo_img">
          <img
            class="qsy_img"
            src="@/assets/images/homeImg/footerLogo.png"
            alt=""
          />
        </div>
        <div class="footer_logo_phone">020-29841142</div>
      </div>
      <div class="footer_company">
        <div class="footer_company_top">
          <a href="javascript:void 0" class="footer_company_top">
          粤公网安备 44010402002770号
            <div class="footer_line">|</div>
          </a>

          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" class="footer_company_top">
            粤ICP备2022050654号
          </a>
        </div>
        <div class="footer_company_bottom">
          广州仟仕云科技有限公司
          <div class="footer_line">|</div>
          Copyright isuperone.com ALL rights reserved
        </div>
      </div>
      <!-- <div class="footer__line">
        <p class="footer__line-item">
         粤公网安备 44010402002770号 粤ICP备2022050654号
        </p>
        <p class="footer__line-item_size">广州仟仕云科技有限公司 Copyright isuperone.com ALL rights reserved</p>
      </div> -->
    </footer>
  </div>
</template>
 
<script>
export default {};
</script>
 
<style lang = "scss" scoped>
.footer {
  margin: 0 auto;
  background: #393939;
  height: 203px;
  min-width: 1200px;
  .footer_logo_box {
    width: 590px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 139px;
    margin: 0 auto;
    .footer_logo_img {
      width: 252px;
      height: 43px;
    }
    .footer_logo_phone {
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .footer_company {
    height: 64px;
    width: 100%;
    background: #2d2d2d;
    overflow: hidden;
    .footer_company_top {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      font-size: 14px;
      text-align: center;
      margin-top: 12px;
    }
    .footer_company_bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      font-size: 14px;
      text-align: center;
      margin-top: 3px;
    }
    .footer_line {
      margin: -3px 8px 0;
    }
  }
}
.qsy_img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>